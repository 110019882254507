import { Material } from "API";

export function getMaterial(ms: Material[], elements: any) {
  const materialElement = elements.filter((e: any) =>
    e.slug.includes("materialId")
  )[0];
  const material = ms.filter((ms) => ms.id === materialElement.value)[0];
  if (!material) return "-";
  return material.name;
}

export function getValue(elements: any, slug: string) {
  const filteredElements = elements.filter((e: any) => e.slug.includes(slug));
  if (filteredElements.length == 0) return null;
  const element = filteredElements[0];
  if (element.type === "number" && isNaN(Number(element.value))) {
    return 0;
  }
  return element.value;
}
