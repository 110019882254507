import { getDocxData } from "./docx";
import { getXlsxData } from "./xlsx";

import type { ReportOptions, TemplateType } from "utils/report/types";

export async function getData(
  type: string,
  templateType: TemplateType,
  projectId: string,
  groupId: string,
  options?: ReportOptions
) {
  if (type === "DOCUMENT") return getDocxData(templateType, projectId, options);
  if (type === "SPREADSHEET")
    return getXlsxData(templateType, projectId, groupId, options);
}
