/* eslint-disable react-hooks/exhaustive-deps */
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useRouter } from "next/router";
import { parseCookies, setCookie } from "nookies";

import { useAlerts } from "./alerts";

import type { Project } from "API";
import useDatalist from "hooks/datalist";
import { GraphQLInput } from "hooks/datalist";
import { useAuth } from "contexts/auth";
import { useDialog } from "contexts/dialog";
import { Status, getStatusIndex, getStatusText } from "utils/status";
import { defaultCookieOptions, parseCookieBooleanValue } from "utils/cookie";
import { RedirectTypes } from "utils/project";

interface ProjectsContextValue {
  loading: boolean;
  creating: boolean;
  projects: Project[];
  loadNext: () => void;
  keyword: string | null;
  setKeyword: (k: string | null) => void;
  isProjectsFilterSaved: boolean;
  setIsProjectsFilterSaved: (visible: boolean) => void;
  statusFilter: Status[];
  setStatusFilter: (k: Status[]) => void;
  copy: (
    input: GraphQLInput,
    redirectType: RedirectTypes.schedules
  ) => Promise<void>;
  create: (
    input: GraphQLInput,
    redirectType: RedirectTypes.schedules
  ) => Promise<void>;
  remove: (id: string) => Promise<void>;
  refetch: (variables?: any) => Promise<void>;
  set: (input: GraphQLInput) => Promise<void>;
}

interface ProjectsContextProps {
  children: ReactNode;
  isForManager?: boolean;
}

const ProjectsContext = createContext<ProjectsContextValue>({
  projects: [],
  loading: false,
  creating: false,
  loadNext: () => null,
  keyword: null,
  setKeyword: () => null,
  isProjectsFilterSaved: false,
  setIsProjectsFilterSaved: () => null,
  statusFilter: [],
  setStatusFilter: () => null,
  copy: () => Promise.resolve(),
  create: () => Promise.resolve(),
  remove: () => Promise.resolve(),
  refetch: () => Promise.resolve(),
  set: () => Promise.resolve(),
});

const filterProject = (
  data: Project[],
  keyword: string | null,
  statusFilter: Status[]
) => {
  let projects = data;
  if (keyword)
    projects = projects.filter(
      (d: Project) =>
        d.name.includes(keyword) ||
        d.accountName?.includes(keyword) ||
        d.contactName?.includes(keyword) ||
        d.reference?.includes(keyword) ||
        getStatusText(d.status).includes(keyword) ||
        d.managerName?.includes(keyword) || //PAX-628 検索項目追加
        d.quoterName?.includes(keyword) ||
        d.user?.firstName?.includes(keyword) ||
        d.operatorName?.includes(keyword) ||
        d.user?.lastName?.includes(keyword)
    );
  if (statusFilter.length > 0) {
    projects = projects.filter((d: Project) => statusFilter.includes(d.status));
  }

  return projects;
};

export const ProjectsProvider = ({
  children,
  isForManager,
}: ProjectsContextProps) => {
  const cookies = parseCookies();
  const isProjectsFilterApplied = parseCookieBooleanValue(
    cookies.isProjectsFilterSaved
  );

  const router = useRouter();
  const { currentGroup, user } = useAuth();
  const [keyword, setKeyword] = useState<string | null>(
    isProjectsFilterApplied && cookies.projectsKeyword
      ? cookies.projectsKeyword
      : null
  );
  const [statusFilter, setStatusFilter] = useState<Status[]>([]);
  const [, setSetting] = useState<boolean>(false);

  // PAX-360 Gantt検索条件はCookieに保存する、そのトグル
  const [isProjectsFilterSaved, setIsProjectsFilterSaved] = useState<boolean>(
    isProjectsFilterApplied
  );

  if (isProjectsFilterSaved) {
    setCookie(null, "projectsKeyword", keyword ?? "", defaultCookieOptions);
  }
  // PAX-360 Cookieには文字列しか格納できないため,文字列"true", "false"を利用
  setCookie(
    null,
    "isProjectsFilterSaved",
    isProjectsFilterSaved ? "true" : "false",
    defaultCookieOptions
  );

  const {
    data,
    loading,
    creating,
    loadNext,
    refetch,
    create,
    remove,
    set,
    nextToken,
    appendNext,
  } = useDatalist({
    // スケジュール画面用のクエリとしてprojectsByGroupIdForManager
    // TODO: 将来的にはprojectsByGroupIdForManagerに統一し、ProjectsProviderを２か所でつかっている現状を１か所に統一を行う
    query: isForManager ? "projectsByGroupIdForManager" : "projectsByGroupId",
    variables: {
      groupId: currentGroup?.id,
      filter: { archived: { ne: true } },
    },
    sort: (a, b) => getStatusIndex(a.status) - getStatusIndex(b.status),
  });

  const { open } = useDialog();
  const { addAlert } = useAlerts();

  // FIXME: 全件取得するようにしている、ページネーションを実装する必要がある
  // appendNextの実行状態を管理しないとデータが重複する
  const [fetchingNext, setFetchingNext] = useState(false);

  // nextTokenがnullになるまでappendNextを実行する
  useEffect(() => {
    if (nextToken && !fetchingNext) {
      // 既にフェッチ中でなければappendNextを呼び出す
      setFetchingNext(true); // フェッチ中の状態をtrueに設定
      appendNext().finally(() => setFetchingNext(false)); // フェッチが終わったらフェッチ中の状態をfalseに設定
    }
  }, [nextToken]);

  useEffect(() => {
    refetch({
      groupId: currentGroup?.id,
      filter: { archived: { ne: true } },
    });
  }, [currentGroup, currentGroup?.id]);

  const refetchProjects = async (customVariables?: any) => {
    await refetch({
      groupId: currentGroup?.id,
      filter: { archived: { ne: true } },
      ...customVariables,
    });
  };

  const copyProject = async (
    input: GraphQLInput,
    redirectType: RedirectTypes.schedules
  ) => {
    const newProject = await create("copyProject", {
      ...input,
      groupId: currentGroup?.id,
      userId: user?.attributes.sub,
    });
    if (!newProject) return;
    let url = `/projects/${(newProject as any).id}`;
    if (redirectType) {
      url += `?page=${redirectType}&modal=true`;
    }
    router.push(url);
  };

  const createProject = async (
    input: GraphQLInput,
    redirectType: RedirectTypes.schedules
  ) => {
    const newProject = await create("generateProject", {
      ...input,
      groupId: currentGroup?.id,
      userId: user?.attributes.sub,
    });
    if (!newProject) return;
    let url = `/projects/${(newProject as any).id}`;
    if (redirectType) {
      url += `?page=${redirectType}&modal=true`;
    }
    router.push(url);
  };

  const setProject = async (input: GraphQLInput) => {
    setSetting(true);
    let projects = filterProject(data, keyword, statusFilter);
    let targetIdx = projects.findIndex((d: GraphQLInput) => d.id === input.id);
    projects[targetIdx] = { ...projects[targetIdx], ...input };
    await set(projects);
    addAlert({ severity: "success", message: "スケジュールを更新しました" });
    setSetting(false);
  };

  const removeProject = async (id: string) => {
    open({
      title: "案件を削除しますか？",
      content: "削除すると二度と戻せません",
      okText: "削除",
      onOk: async () => await remove("deleteProject", { id }),
    });
  };

  return (
    <ProjectsContext.Provider
      value={{
        projects: filterProject(data, keyword, statusFilter),
        loading,
        creating,
        loadNext,
        keyword,
        setKeyword,
        isProjectsFilterSaved,
        setIsProjectsFilterSaved,
        statusFilter,
        setStatusFilter,
        copy: copyProject,
        create: createProject,
        remove: removeProject,
        refetch: async (customVariables) =>
          await refetchProjects(customVariables),
        set: setProject,
      }}
    >
      {children}
    </ProjectsContext.Provider>
  );
};

export const useProjects = () => {
  const projectsContext = useContext(ProjectsContext);

  if (projectsContext === undefined) {
    throw new Error("useProjects must be within ProjectsProvider");
  }

  return projectsContext;
};
