// タイムゾーン付きの日付文字列を日本ロケールの日付文字列(フォーマットは2006年01月02日)に変換する
export const dateStringToJaDateStr = (dateString: string): string | Error => {
  // Date型に変換
  const date = new Date(dateString);

  // 変換に失敗したらエラーを返す
  if (isNaN(date.getTime())) {
    return new Error("Invalid Date");
  }

  // option指定
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  // 日本ロケールの日付文字列に変換
  const jaDateStr = date.toLocaleDateString("ja-JP", options);

  // "/"を"年"と"月"に置換
  return jaDateStr.replace("/", "年").replace("/", "月") + "日";
};

// タイムゾーン付きの日付文字列を日本ロケールの日付時刻文字列(フォーマットは2006年01月02日 15時04分05秒)に変換する
export const dateStringToJaDateTimeStr = (
  dateString: string
): string | null => {
  // Date型に変換
  const date = new Date(dateString);

  // 変換に失敗したらエラーを返す
  if (isNaN(date.getTime())) {
    return null;
  }

  // option指定
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  // 日本ロケールの日付文字列に変換
  const jaDateTimeStr = date.toLocaleDateString("ja-JP", options);

  // "/"を"年"と"月"に置換
  return (
    jaDateTimeStr
      .replace("/", "年")
      .replace("/", "月")
      .replace(" ", "日 ")
      .replace(":", "時")
      .replace(":", "分") + "秒"
  );
};

/**
 * date1とdate2を比較して、新しい方の日付文字列を返す
 * @param date1 比較対象1(YYYY/MM/DD HH:mm:ss)
 * @param date2 比較対象2(YYYY/MM/DD HH:mm:ss)
 */
export const pickLatestDateString = (
  date1: string | undefined,
  date2: string | undefined
): string => {
  // どちらもundefinedか空文字だったら空文字を返す
  if (!date1 && !date2) return "";

  // どちらかがundefined or 空文字だったらもう片方を返す
  if (!date1) return date2 ?? "";
  if (!date2) return date1 ?? "";

  // Dateにして比較して新しい方を返す
  return new Date(date1) > new Date(date2) ? date1 : date2;
};
