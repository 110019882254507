import { API } from "aws-amplify";

import { getAllProduct } from "graphql/product";
import { dateStringToJaDateTimeStr } from "utils/date";

export async function getServiceData(productId: string) {
  const p = (
    await API.graphql({
      query: getAllProduct,
      variables: { id: productId },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
  ).data.getProduct;

  const service = {
    id: p.id,
    productNumber: p.productNumber || "-",
    name: p.name || "-",
    quantity: p.quantity || "-",
    stockingDate: p.schedule.stockingDate || "-",
    shippingDate: p.schedule.shippingDate || "-",
    createdAt: p.createdAt ? dateStringToJaDateTimeStr(p.createdAt) : "-",
    updatedAt: p.updatedAt ? dateStringToJaDateTimeStr(p.updatedAt) : "-",
  };

  const data = {
    service,
  };

  return data;
}
