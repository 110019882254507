export type Status =
  | "QUOTE"
  | "ORDER"
  | "STOCK"
  | "PACK"
  | "SHIP"
  | "INVOICE"
  | "COMPLETE"
  | "CANCEL"
  | string
  | null
  | undefined;

export function getStatusIndex(status: Status): number {
  switch (status) {
    case "QUOTE":
      return 0;
    case "ORDER":
      return 1;
    case "STOCK":
      return 2;
    case "PACK":
      return 3;
    case "SHIP":
      return 4;
    case "INVOICE":
      return 5;
    case "COMPLETE":
      return 6;
    case "CANCEL":
      return 7;
    default:
      return 8;
  }
}

export function getStatusColor(status: Status) {
  switch (status) {
    case "QUOTE":
      return "#777";
    case "ORDER":
      return "#3e6eb3";
    case "STOCK":
      return "#29BF12";
    case "PACK":
      return "#3e6eb3";
    case "SHIP":
      return "#d18643";
    case "CUT":
      return "#bd3993";
    case "INVOICE":
      return "#bd3993";
    case "CANCEL":
      return "#e53935";
    default:
      return "#000";
  }
}

export function getStatusInitial(status: Status) {
  switch (status) {
    case "QUOTE":
      return "見";
    case "ORDER":
      return "受";
    case "STOCK":
      return "入";
    case "PACK":
      return "梱";
    case "SHIP":
      return "出";
    case "CUT":
      return "C";
    case "INVOICE":
      return "請";
    case "COMPLETE":
      return "完";
    case "CANCEL":
      return "失";
    default:
      return "?";
  }
}

export function getStatusText(status: Status) {
  switch (status) {
    case "QUOTE":
      return "見積";
    case "ORDER":
      return "受注";
    case "STOCK":
      return "入荷";
    case "PACK":
      return "梱包";
    case "SHIP":
      return "出荷";
    case "CUT":
      return "CUT";
    case "INVOICE":
      return "請求";
    case "COMPLETE":
      return "完了";
    case "CANCEL":
      return "失注";
    default:
      return "?";
  }
}

export function getStatusFromTabIndex(index: number) {
  switch (index) {
    case 1:
      return "STOCK";
    case 2:
      return "PACK";
    case 3:
      return "SHIP";
    default:
      return "DETAIL";
  }
}

export function getTabIndex(status: Status) {
  switch (status) {
    case "QUOTE":
      return 0;
    case "ORDER":
      return 0;
    case "STOCK":
      return 1;
    case "PACK":
      return 2;
    case "SHIP":
      return 3;
    case "INVOICE":
      return 0;
    default:
      return 0;
  }
}

export const status = [
  { value: "QUOTE", label: "見積" },
  { value: "ORDER", label: "受注" },
  { value: "STOCK", label: "入荷" },
  { value: "PACK", label: "梱包" },
  { value: "SHIP", label: "出荷" },
  { value: "INVOICE", label: "請求" },
  { value: "COMPLETE", label: "完了" },
  { value: "CANCEL", label: "失注" },
];
