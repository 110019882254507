import axios from "axios";

import { Report } from "API";

export async function renderSpreadsheet(blob: Blob, data: any, report: Report) {
  const body = new FormData();

  body.append("file", blob);
  body.append("data", JSON.stringify(data));
  body.append("report", JSON.stringify(report));

  // 帳票のセルが多すぎるとaxiosの10secのタイムアウトを越すので拡張
  const res = await axios.post("/api/xlsx", body, {
    timeout: 120 * 1000,
  });

  return res.data;
}
