import { getProjectData } from "./project";
import { getQuoteData } from "./quote";
import { getServiceData } from "./service";
import { getInvoiceData } from "./invoice";

import type { ReportOptions, TemplateType } from "utils/report/types";

export async function getDocxData(
  templateType: TemplateType,
  projectId: string,
  options?: ReportOptions
) {
  let promises = [];
  promises.push(getProjectData(projectId));
  if (templateType === "QUOTE" && options?.quoteId) {
    promises.push(getQuoteData(options.quoteId));
  }
  if (templateType === "SERVICE" && options?.productId) {
    promises.push(getServiceData(options.productId));
  }
  if (templateType === "INVOICE") {
    promises.push(getInvoiceData(projectId));
  }
  let [projectData, secondaryData] = await Promise.all(promises);
  return { ...projectData, ...secondaryData };
}
