import { API } from "aws-amplify";

import { QuoteLine } from "API";
import { getQuote, quoteSummariesByQuoteId } from "graphql/queries";
import { dateStringToJaDateTimeStr } from "utils/date";

export async function getQuoteData(quoteId: string) {
  const q = (
    await API.graphql({
      query: getQuote,
      variables: { id: quoteId },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
  ).data.getQuote;

  const qss = (
    await API.graphql({
      query: quoteSummariesByQuoteId,
      variables: { input: { quoteId } },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
  ).data.quoteSummariesByQuoteId.items;

  const quote = {
    "quote.id": q.id,
    "quote.name": q.name || "-",
    "quote.quoteNumber": q.quoteNumber || "-",
    "quote.expiryDate": q.expiryDate || "-",
    "quote.description": q.description || "",
    "quote.destination": q.destination || "-",
    "quote.greeting": q.greeting || "",
    "quote.totalNumOfCases": q.totalNumOfCases || "-",
    "quote.totalNetWeight": q.totalNetWeight || "-",
    "quote.totalGrossWeight": q.totalGrossWeight || "-",
    "quote.totalM3": q.totalM3 || "-",
    "quote.revenue": q.revenue || "-",
    "quote.createdAt": q.createdAt
      ? dateStringToJaDateTimeStr(q.createdAt)
      : "-",
    "quote.updatedAt": q.updatedAt
      ? dateStringToJaDateTimeStr(q.updatedAt)
      : "-",
  };

  const qls = q.quoteLines.items;

  const sortedQls = [
    ...qls
      .filter((ql: QuoteLine) => ql.showInQuoteSummary)
      .sort((a: QuoteLine, b: QuoteLine) => {
        return a.order > b.order ? 1 : -1;
      }),
    ...qls
      .filter((ql: QuoteLine) => !ql.showInQuoteSummary)
      .sort((a: QuoteLine, b: QuoteLine) => {
        return a.order > b.order ? 1 : -1;
      }),
  ];

  const quoteLines = sortedQls.map((ql: QuoteLine, idx: number) => ({
    number: idx + 1,
    index: ql.index || "-",
    name: ql.name || "-",
    packageTypeName: ql.packageTypeName || "-",
    pricingName: ql.pricingName || "-",
    quantity: ql.quantity || "-",
    amount: ql.amount || "-",
    outerLength: ql.outerLength || "-",
    outerWidth: ql.outerWidth || "-",
    outerHeight: ql.outerHeight || "-",
    productLength: ql.productLength || "-",
    productWidth: ql.productWidth || "-",
    productHeight: ql.productHeight || "-",
    unit: ql.unit || "-",
    unitNW: ql.unitNW || "-",
    NW: ql.NW || "-",
    unitPrice: ql.unitPrice || "-",
    taxType: ql.taxType || "-",
    taxRate: ql.taxRate || "-",
    subtotal: ql.subtotal || "-",
    total: ql.total || "-",
    notes: ql.notes || "-",
    estimatedUnitGrossWeight: ql.estimatedUnitGrossWeight || "-",
    estimatedGrossWeight: ql.estimatedGrossWeight || "-",
    estimatedUnitM3: ql.estimatedUnitM3?.toFixed(3) || "-",
    estimatedM3: ql.estimatedM3?.toFixed(3) || "-",
    estimatedUnitCost: ql.estimatedUnitCost || "-",
    estimatedCost: ql.estimatedCost || "-",
    estimatedProfit: ql.estimatedProfit || "-",
    createdAt: ql.createdAt ? dateStringToJaDateTimeStr(ql.createdAt) : "-",
    updatedAt: ql.updatedAt ? dateStringToJaDateTimeStr(ql.updatedAt) : "-",
    showInQuoteSummary: ql.showInQuoteSummary || false,
  }));

  const quoteSummaries = qss.map((qs: any, idx: number) => ({
    number: idx + 1,
    name: qs.name || "-",
    pricingName: qs.pricingName || "-",
    amount: qs.amount || 0,
    unit: qs.unit || "-",
    unitPrice: qs.unitPrice || 0,
    subtotal: qs.subtotal || 0,
    taxType: qs.taxType || "-",
    notes: qs.notes || "",
  }));

  const data = {
    ...quote,
    quoteLines,
    quoteSummaries,
    otherQuoteLines: quoteLines.filter((l) => !(l.showInQuoteSummary ?? false)),
    packQuoteLines: quoteLines.filter((l) => l.showInQuoteSummary ?? false),
  };

  return data;
}
