export function getProjectTypeName(
  type: "PACK" | "OTHER" | string | null | undefined
) {
  switch (type) {
    case "PACK":
      return "梱包";
    case "OTHER":
      return "その他";
    default:
      return "梱包";
  }
}

export enum RedirectTypes {
  schedules = "schedules",
}
