import { Button } from "@mui/material";

import { useAuth } from "contexts/auth";
export default function AccessGate() {
  const { logout } = useAuth();
  return (
    <div
      role="alert"
      className="w-full mt-24 flex flex-col justify-center items-center"
    >
      <h1 className="text-3xl font-bold mb-3">ライセンスエラー</h1>
      <p className="mb-4">システム管理者に連絡して下さい</p>
      <Button onClick={logout} variant="outlined">
        ログアウト
      </Button>
    </div>
  );
}
