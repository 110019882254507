import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useAuth } from "./auth";
import { useTenant } from "./tenant";

import type { User, UsersTenants } from "API";
import { GraphQLInput } from "hooks/data";
import useDatalist from "hooks/datalist";
import { LicenseEnum } from "utils/license";

export interface TenantUser extends User {
  license: LicenseEnum;
  usersTenantsId: string;
  active: boolean;
}

interface UsersContextValue {
  loading: boolean;
  users: TenantUser[];
  managerUsers: TenantUser[];
  mobileUsers: TenantUser[];
  unlincensedUsers: TenantUser[];
  supportUsers: TenantUser[];
  isLicenseValid: boolean;
  isLicenseValidForMobile: boolean;
  isLicenseValidForManager: boolean;
  isLicenseValidForMe: boolean;
  myLicense: LicenseEnum;
  canRemoveAdmin: boolean; // システム管理者権限を持つユーザーが1人のみの場合は削除できない
  invite: (input: any) => Promise<void>;
  update: (input: any) => Promise<void>;
  refetch: () => Promise<void>;
  set: (input: GraphQLInput) => void;
}

interface UsersContextProps {
  children: ReactNode;
}

const UsersContext = createContext<UsersContextValue>({
  users: [],
  managerUsers: [],
  mobileUsers: [],
  supportUsers: [],
  unlincensedUsers: [],
  isLicenseValid: false,
  isLicenseValidForMobile: false,
  isLicenseValidForManager: false,
  isLicenseValidForMe: true,
  myLicense: LicenseEnum.MANAGER,
  canRemoveAdmin: false,
  loading: false,
  invite: () => Promise.resolve(),
  update: () => Promise.resolve(),
  refetch: () => Promise.resolve(),
  set: () => {},
});

export const UsersProvider = ({ children }: UsersContextProps) => {
  const { user } = useAuth();
  const { tenant } = useTenant();
  const { data, loading, create, update, refetch, set, nextToken, appendNext } =
    useDatalist({
      query: user?.disableMultiTenant
        ? "legacyUsersByTenantId"
        : "usersByTenantId",
      variables: {
        tenantId: user?.tenantId,
      },
    });

  // FIXME: 全件取得するようにしている、ページネーションを実装する必要がある
  // appendNextの実行状態を管理しないとデータが重複する
  const [fetchingNext, setFetchingNext] = useState(false);

  // nextTokenがnullになるまでappendNextを実行する
  useEffect(() => {
    if (nextToken && !fetchingNext) {
      // 既にフェッチ中でなければappendNextを呼び出す
      setFetchingNext(true); // フェッチ中の状態をtrueに設定
      appendNext().finally(() => setFetchingNext(false)); // フェッチが終わったらフェッチ中の状態をfalseに設定
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextToken]);

  const users: TenantUser[] = data.map((d: UsersTenants) => ({
    ...d.user,
    ...d,
    usersTenantsId: d.id,
    groups: d.user?.groups,
    id: d.user?.id,
  }));

  const managerUsers = users.filter(
    (user) => user.license === LicenseEnum.MANAGER
  );

  const mobileUsers = users.filter(
    (user) => user.license === LicenseEnum.MOBILE
  );

  const unlincensedUsers = users.filter(
    (user) => user.license === LicenseEnum.NONE || !user.license
  );

  const supportUsers = users.filter(
    (user) => user.license === LicenseEnum.SUPPORT
  );

  const canRemoveAdmin =
    users.filter(
      (user) =>
        user.isAdmin &&
        user.active &&
        !!user.license &&
        user.license !== LicenseEnum.SUPPORT
    ).length > 1;

  const isLicenseValidForManager =
    users.filter((user) => user.license === LicenseEnum.MANAGER).length <=
    (tenant?.maxManagerLicense || 0);

  const isLicenseValidForMobile =
    users.filter((user) => user.license === LicenseEnum.MOBILE).length <=
    (tenant?.maxMobileLicense || 0);

  const isLicenseValid = isLicenseValidForManager && isLicenseValidForMobile;

  const me = users.find((usr) => usr.id === user?.id);

  const myLicense = me?.license || LicenseEnum.NONE;

  const isLicenseValidForMe =
    me && tenant
      ? (myLicense === LicenseEnum.MANAGER &&
          isLicenseValidForManager &&
          me.active) ||
        (myLicense === LicenseEnum.SUPPORT && me.active)
      : true;

  const inviteMember = async (input: GraphQLInput) => {
    await create("inviteUser", {
      ...input,
      tenantId: user?.tenantId,
      multiTenant: true,
    });
  };

  const updateMember = async (input: GraphQLInput) => {
    await update("updateUsersTenants", {
      ...input,
    });
  };

  const values = useMemo(
    () => ({
      users,
      invite: inviteMember,
      update: updateMember,
      loading,
      refetch: () => refetch({ tenantId: user?.tenantId }),
      set,
      managerUsers,
      mobileUsers,
      supportUsers,
      unlincensedUsers,
      isLicenseValid,
      isLicenseValidForMobile,
      isLicenseValidForManager,
      isLicenseValidForMe,
      myLicense,
      canRemoveAdmin,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      inviteMember,
      loading,
      set,
      updateMember,
      users,
      managerUsers,
      mobileUsers,
      supportUsers,
      unlincensedUsers,
      isLicenseValid,
      isLicenseValidForMobile,
      isLicenseValidForManager,
      isLicenseValidForMe,
      myLicense,
      canRemoveAdmin,
    ]
  );

  return (
    <UsersContext.Provider value={values}>{children}</UsersContext.Provider>
  );
};

export const useUsers = () => {
  const usersContext = useContext(UsersContext);

  if (usersContext === undefined) {
    throw new Error("useUsers must be within UsersProvider");
  }

  return usersContext;
};
