import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from "react";

import { useAuth } from "./auth";

import type { Tenant } from "API";
import useData, { GraphQLInput } from "hooks/data";

interface TenantContextValue {
  loading: boolean;
  tenant: Tenant | undefined;
  update: (input: GraphQLInput) => Promise<void>;
  refetch: () => Promise<void>;
  set: (input: GraphQLInput) => void;
}

interface TenantContextProps {
  caseOrder?: string | null | undefined;
  children: ReactNode;
}

const TenantContext = createContext<TenantContextValue>({
  tenant: undefined,
  loading: false,
  update: () => Promise.resolve(),
  refetch: () => Promise.resolve(),
  set: () => Promise.resolve,
});

export const TenantProvider = ({ children }: TenantContextProps) => {
  const { user } = useAuth();
  const { data, loading, refetch, update, set } = useData({
    object: "tenant",
    variables: {
      id: user?.tenantId,
    },
  });

  useEffect(() => {
    refetch({
      id: user?.tenantId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.tenantId]);

  const updateTenant = async (input: GraphQLInput) => {
    await update(input);
  };

  const values = useMemo(
    () => ({
      tenant: data,
      update: updateTenant,
      loading,
      refetch: () => refetch({ id: user?.tenantId }),
      set,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, update, loading, set]
  );

  return (
    <TenantContext.Provider value={values}>{children}</TenantContext.Provider>
  );
};

export const useTenant = () => {
  const tenantContext = useContext(TenantContext);

  if (tenantContext === undefined) {
    throw new Error("useTenant must be within TenantProvider");
  }

  return tenantContext;
};
