import { getProjectData } from "./project";
import { getQuoteData } from "./quote";
import { getProductData } from "./product";
import { getServiceData } from "./service";
import { getProductSummaryData } from "./productSummary";
import { getInvoiceData } from "./invoice";

import type { ReportOptions, TemplateType } from "utils/report/types";

export async function getXlsxData(
  templateType: TemplateType,
  projectId: string,
  groupId: string,
  options?: ReportOptions
) {
  const promises = [];
  promises.push(getProjectData(projectId));
  if (templateType === "QUOTE" && options?.quoteId) {
    promises.push(getQuoteData(options.quoteId));
  }
  if (templateType === "SERVICE") {
    promises.push(getServiceData(options?.productId as string));
  }
  if (templateType === "PRODUCT" || templateType === "CASE") {
    promises.push(getProductData(options?.productId as string, groupId));
  }
  if (templateType === "PRODUCTS") {
    promises.push(
      getProductSummaryData(
        projectId as string,
        options?.productSummariesFilter,
        options?.lineOrderMapping
      )
    );
  }
  if (templateType === "INVOICE") {
    promises.push(getInvoiceData(options?.invoiceId as string, projectId));
  }
  const [projectData, secondaryData] = await Promise.all(promises);
  return { ...projectData, ...secondaryData };
}
