import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
let PizZipUtils: any = null;
if (typeof window !== "undefined") {
  import("pizzip/utils/index.js").then(function (r) {
    PizZipUtils = r;
  });
}

// https://docxtemplater.com/docs/get-started-browser/

function loadFile(blob: Blob) {
  return new Promise((resolve, reject) => {
    const url = URL.createObjectURL(blob);
    PizZipUtils.getBinaryContent(url, (err: any, data: any) => {
      if (err) reject(err);
      resolve(data);
    });
  });
}

//The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
//function replaceErrors(key: any, value: any) {
//if (value instanceof Error) {
//return Object.getOwnPropertyNames(value).reduce(function (
//error: any,
//key: any
//) {
//error[key] = (value as any)[key];
//return error;
//},
//{});
//}
//return value;
//}

function modifyContent(content: any, data: any) {
  var zip = new PizZip(content);
  var doc = new Docxtemplater().loadZip(zip);
  doc.setData(data);
  try {
    // render the document
    doc.render();
  } catch (error: any) {
    if (error.properties && error.properties.errors instanceof Array) {
      const errorMessages = error.properties.errors
        .map(function (error: any) {
          return error.properties.explanation;
        })
        .join("\n");
      throw Error(errorMessages);
      // errorMessages is a humanly readable message looking like this :
      // 'The tag beginning with "foobar" is unopened'
    }
    throw error;
  }
  var blob = doc.getZip().generate({
    type: "blob",
    mimeType:
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  });
  // Output the document using Data-URI
  return blob;
}

export async function renderDocument(blob: Blob, data: any) {
  const file = await loadFile(blob);
  return modifyContent(file, data);
}
