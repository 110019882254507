export enum LicenseEnum {
  MANAGER = "MANAGER",
  MOBILE = "MOBILE",
  SUPPORT = "SUPPORT",
  NONE = "NONE",
}

export function getLicenseName(license: LicenseEnum): string {
  switch (license) {
    case LicenseEnum.MANAGER:
      return "標準";
    case LicenseEnum.MOBILE:
      return "モバイル";
    case LicenseEnum.SUPPORT:
      return "サポート";
    case LicenseEnum.NONE:
      return "無効";
    default:
      return "-";
  }
}

export function getLicenseOptions(): { value: LicenseEnum; label: string }[] {
  return [
    { value: LicenseEnum.NONE, label: getLicenseName(LicenseEnum.NONE) },
    { value: LicenseEnum.MANAGER, label: getLicenseName(LicenseEnum.MANAGER) },
    { value: LicenseEnum.MOBILE, label: getLicenseName(LicenseEnum.MOBILE) },
  ];
}
