import { API } from "aws-amplify";

import { getAllProduct } from "graphql/product";
import { dateStringToJaDateTimeStr } from "utils/date";

export async function getServiceData(productId: string) {
  const p = (
    await API.graphql({
      query: getAllProduct,
      variables: { id: productId },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
  ).data.getProduct;

  const service = {
    "service.id": p.id,
    "service.productNumber": p.productNumber || "-",
    "service.name": p.name || "-",
    "service.quantity": p.quantity || "-",
    "service.stockingDate": p.schedule.stockingDate || "-",
    "service.shippingDate": p.schedule.shippingDate || "-",
    "service.createdAt": p.createdAt
      ? dateStringToJaDateTimeStr(p.createdAt)
      : "-",
    "service.updatedAt": p.updatedAt
      ? dateStringToJaDateTimeStr(p.updatedAt)
      : "-",
  };

  const data = {
    ...service,
  };

  return data;
}
